.form {
  & :global(.chakra-radio__control) {
    border-width: 2px;
  }

  & :global(.chakra-radio__control[data-checked]) {
    border-width: 6px;
  }

  & :global(.radio_group_black) {
    & :global(.chakra-checkbox__control[data-checked]) {
      & div {
        color: black;
      }
    }

    & :global(.chakra-checkbox[data-error='true']) {
      & > :global(.chakra-checkbox__control) {
        border-color: #e53e3e;
      }
    }

    & :global(.chakra-radio__control) {
      background: white !important;
      border-color: black !important;
    }

    & :global(.chakra-radio__control[data-checked]) {
      background: white !important;
      border-color: black !important;
    }

    & :global(.chakra-radio__control[data-error='true']) {
      border-color: #e53e3e;
    }
  }

  & :global(.radio_group_white) {
    & :global(.chakra-checkbox__control[data-checked]) {
      & div {
        color: white;
      }
    }

    & :global(.chakra-checkbox[data-error='true']) {
      & > :global(.chakra-checkbox__control) {
        border-color: #e53e3e;
      }
    }

    & :global(.chakra-radio__control) {
      background: black !important;
      border-color: white !important;
    }

    & :global(.chakra-radio__control[data-checked]) {
      background: black !important;
      border-color: white !important;

      &::before {
        background: black !important;
      }
    }

    & :global(.chakra-radio__control[data-error='true']) {
      border-color: #e53e3e;
    }
  }
}
